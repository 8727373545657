//
// Core: Layout
//

html.scroll-smooth {
  scroll-behavior: smooth;
}

html,
body,
.page {
  height: 100%;
  // min-width: 1280px;
}

// General :: ScrollBar

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $gray-500;
}

// Page Setup
// .content-wrapper {
//   position: relative;
//   padding: 0;
//   margin: 0;

//   &.map-content-wrapper {
//     height: 100%;
//   }

//   &.map-content {
//     overflow: hidden;
//   }

//   .page-header {
//     padding: 6px #{$grid-gutter-width / 2};
//     margin-bottom: 5px;

//     display: flex !important;
//     justify-content: space-between !important;
//     -webkit-justify-content: space-between !important;
//     -ms-flex-pack: justify !important;

//     &.border {
//       border: 0 !important;
//       border-bottom: 1px solid $gray-300 !important;
//     }

//     &.shadow {
//       box-shadow: 0px 0px 15px 0px rgba(25, 104, 252, 0.2) !important;
//     }
//   }

//   .page-content {
//     padding: 6px #{$grid-gutter-width / 2};
//   }
// }

// Navbar

.navbar {
  .main-branding {
    width: $sidebar-width;
  }
}

.navbar-branding {
  .navbar-subtext {
    color: white;
    margin-top: 8px;
  }
}

// Modal

.modal-backdrop {
  z-index: 2040;
}

.modal {
  z-index: 2050;
}
