.page-topbar {
  background-color: $topbar-color;
}

.sidebar {
  background-color: $sidebar-color;
}

.sidebar {
  ul {
    li {
      .menu-link {
        &:hover {
          color: $menu-hover;
          i {
            color: $menu-hover;
          }
          .link-name {
            color: $menu-hover;
          }
        }
      }
    }
  }
}

.menu-icons {
  color: $menu-color;
}

.sidebar .link-name {
  color: $menu-color;
}

.sidebar .selected-menu {
  color: $selected-menu-color;
}

.sub-menu a {
  color: $menu-color;
}

.sub-menu a:hover {
  color: $menu-hover;
}

.sub-menu {
  background-color: $sidebar-color;
}
