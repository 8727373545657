html,
body {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

// Page Section

h1.sec-title {
  font-size: 1.25rem;
}

h4.sec-title {
  font-size: 1rem;
}

div.sec-info {
  margin-top: 8px;
  padding-top: 5px;
  padding-bottom: 8px;

  .sec-title {
    font-weight: 600;
  }

  p {
    margin-bottom: 2px;
  }
}

.bold {
  font-weight: 600;
}

// Pagination

.pagination-cont {
  margin-top: 12px;
  margin-bottom: 8px;
}

// Table
table {
  $table-border: 1px solid
    darken(
      $color: $body-bg-alt,
      $amount: 5%,
    ) !important;

  thead {
    border: $table-border;
  }

  tr {
    border-left: $table-border;
    border-right: $table-border;

    &:last-child {
      border-bottom: $table-border;
    }
  }

  th {
    background-color: $body-bg-alt !important;
    font-weight: 600 !important;
  }

  td {
    border-top: $table-border;
  }

  .sub-text {
    font-size: 0.75rem;
    color: $gray-600;
    display: block;
  }

  .tr-sec-header {
    font-weight: bold;
    text-align: center;
    td {
      background-color: $gray-100 !important;
    }
  }
}

.table-truncate {
  max-width: 10rem;
}

.table-compact {
  th {
    padding: 4px;
  }
  td {
    padding: 0px;
  }

  .form-error {
    border: 1px double $red !important;
  }

  .selected {
    border: 1px double $blue !important;
  }
}

// Buttons and Button Container

.btn-primary {
  color: white !important;
}

.btn-cont {
  button,
  .btn {
    margin: 2px 4px;

    &:first-child {
      margin-left: 0px;
    }
  }
}

.btn i {
  margin-right: 6px;
}

// Tabs
.nav-tabs {
  .nav-link {
    padding-left: 28px;
    padding-right: 28px;
    background-color: transparent;
    font-size: 18px;

    &.active {
      //
    }
  }
}

.nav-pills {
  .nav-link {
    &.active {
      //
    }
  }
}

.tab-content {
  .tab-pane {
    padding: 12px 0px;
  }
}

// Forms

.form-group {
  margin-bottom: 1rem;
}

.card-inline {
  display: inline-block;
  margin: 10px 10px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
}

.card-info,
.card-simple,
.info-card {
  .card-body {
  }

  div {
    margin-top: 4px;
    margin-bottom: 8px;

    &.sec-info {
      margin-top: 8px;
    }
  }

  p,
  .title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  label {
  }
}

.cont-address {
  p {
    font-weight: normal !important;
    margin-bottom: 4px !important;

    &.addr-title {
      font-weight: 600 !important;
      margin-bottom: 6px !important;
    }
  }
}

// Modals

.modal {
  //
}

// Timeline

.fa-fade-right {
  -webkit-animation: fade-right 1.5s linear infinite;
  animation: fade-right 1.5s linear infinite;
}

.timeline {
  margin-top: 12px;

  .far,
  .fas {
    //
  }

  .event-item {
    border: 1px solid transparent;

    h5 {
    }

    p {
      font-weight: normal !important;
    }

    &.inactive {
      color: $gray-400;
    }

    &:hover {
      color: inherit;
      border: 1px solid #f6f6f6;
      background-color: tint-color($info, 90%);
    }
  }

  &.timeline-vertical {
    border-left: 3px dashed #f6f6f6;
    border-left: 3px dashed var(--bs-gray-300);
    margin: 20px 10px !important;

    .event-item {
      padding: 10px 10px 10px 24px !important;
      position: relative;

      .event-item-dot {
        font-size: 24px;
        left: -12px;
        position: absolute;
        top: 8px;
        z-index: 9;
      }
    }
  }
}

// Dropdown
.dropdown {
  &__menu {
    width: 100%;
    border-radius: 5px !important;
  }

  &__button {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
  }

  &__text {
    white-space: normal;
    color: $gray-900;
    margin-right: 5px;

    &--default {
      @extend .dropdown__text;
      color: $gray-600;
    }
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  padding: 3px;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}

.dropdown-menu {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin-top: 55px !important;
  position: absolute;
  z-index: 1000;
}

//Login and Forgot Password
.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
  position: absolute;
  top: -50px;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.bg-light-blue {
  background-color: $light-blue;
}

// Topbar
.topbar {
  box-shadow: $box-shadow;
  transition: 0.5s;
}

// .navbar-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: $header-height;

//   .dropdown .show {
//     &.header-item {
//       background-color: $gray-100;
//     }
//   }
// }

//Footer
.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} * 0.75);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: 0;
  margin-left: 250px;
  height: $footer-height;
}

//Content Wrapper
// .content-wrapper {
//   padding: 15px;
//   margin-top: 80px;
//   margin-left: 250px;
//   transition: all 0.5s ease;
// }

.sidebar .nav-links {
  height: 100vh;
  padding: 15px;
  overflow: auto;
}

.nav-links {
  li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
  }
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #7f8387;
  font-weight: 600;
  margin-top: 12px;

  &:first-child {
    margin-top: 0px;
  }
}

.menu-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-icons {
  height: 50px;
  min-width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menu-items {
  &.show-sub-menu {
    .arrow {
      transform: rotate(-180deg);
    }
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
}

.sidebar .link-name {
  font-weight: 400;
  cursor: pointer;
  animation: cssAnimation 0s 0.3s forwards; //Sidebar Opening animation
  visibility: hidden;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

.sub-menu {
  padding: 0px 6px 0px 50px;
  margin-top: 0px;
  display: none;
}

.sub-menu a {
  padding: 5px 0;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.sub-menu a:hover {
  opacity: 1;
}

.sidebar .collapsed .link-name {
  opacity: 0;
  pointer-events: none;
}

.collapsed .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.collapsed li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.collapsed .sub-menu .link-name {
  font-size: 18px;
  opacity: 1;
}

// Sidebar Header
.sidebar header {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Sidebar Footer
.sidebar {
  .sidebar-footer {
    background-color: $gray-900;
    padding: 12px 0px 12px 28px;

    i {
      color: $white;
    }

    div {
      color: $white;
    }
  }
}

.collapsed .sidebar-footer {
  display: flex;
  justify-content: center;
  padding: 0px;

  span {
    display: none;
  }
}

// Image
.image img {
  width: 120px;
}
.image-logo img {
  width: 30px;
}

//icon size
.fa-2xs {
  font-size: 0.625em !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-readonly {
  input,
  select,
  textarea {
    border: 1px solid transparent;
    background-color: transparent;
    padding-left: 0;
  }

  input[disabled],
  select[disabled],
  textarea[disabled] {
    background-color: transparent;
    resize: none;
  }
}

// Utitlities

.cursor-pointer {
  cursor: pointer;
}

.text-truncate-2 {
  // for truncate after 2-lines
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-3 {
  // for truncate after 3-lines
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// // Form Elements

.input-search {
  max-width: 420px;
}

// Page Specific CSS

#tab-content {
  .nav-item {
    border: 1px solid $gray-200;
    border-right: 0px;
    border-bottom: 0px;

    &:first-child {
      border-bottom: 1px;
    }
  }

  .nav-pills {
    .nav-link {
      background-color: transparent;

      &.active,
      &:hover {
        background-color: $gray-100;
        color: $gray-900;
      }
    }

    &.flex-column {
      .nav-link {
        border-radius: 6px 0px 0px 6px;
      }
    }
  }

  .right-tab-content {
    background-color: $gray-100;
    min-height: 680px;
    padding: 0px 16px;
  }

  .left-tab-content {
    height: 680px !important;
    overflow: auto !important;
  }
}

.card-component-select {
  background-color: $gray-100;

  &:hover {
    background-color: $gray-200;
  }
}

// SearchDropdown Style
.dropdown-menu.show {
  margin-top: 45px !important;
}
