
// Colors :: Gray
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


// Colors :: Theme
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;


// Additional Colors
$primary: #556ee6;
$light-blue: #d4daf9;
$footer-color: #262b3c;


// Body
$border-radius: 0.125rem;
$body-bg: $white;
$body-bg-alt : #F3F7FF;

// Body :: Font
$font-family-base: "Poppins", "Roboto", "Helvetica", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 0.8rem;
$font-weight-medium: 500;

// Body :: Box Shadow
$box-shadow: 0 0.75rem 1.5rem rgba(93, 96, 96, 0.1);


// Additional Variables
// ----------------------------------------------------------------------------


$header-height: 70px;
$grid-gutter-width: 24px;
$sidebar-close-width: 88px;
$grid-gutter-width: 24px;
$footer-height: 60px;
$collpase-margin-left: 88px;


// Other
$main-header-height : 4rem;
$main-footer-height : 0rem;
$zindex-main-header : 2000;

$navbar-padding-x: #{$grid-gutter-width / 2};
$navbar-padding-y : .25rem;
$nav-link-height : 1.125rem;

$sidebar-width : 15rem;

$content-padding-x : #{$grid-gutter-width / 2};
$content-padding-y : 1.5rem;

