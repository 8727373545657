// Variables
$gray-500: #adb5bd !default;

// For Preview
#report {
  word-wrap: break-word;
  padding: 15px 70px;

  section {
    border-bottom: 1px solid black;
    padding: 50px 0px;
  }

  // Table
  table {
    $table-border: 1px solid $gray-500;

    thead {
      border: $table-border !important;
    }

    tr {
      border-left: $table-border !important;
      border-right: $table-border !important;

      &:last-child {
        border-bottom: $table-border !important;
      }
    }

    td,
    th {
      border: $table-border !important;
    }
  }

  p {
    text-align: justify;
  }
}
