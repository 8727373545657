@import "_variables.scss";

//
// Core: Layout
//

// html,
// body,
// .page {
//   min-width: 1280px;
// }

// General :: ScrollBar

html.scroll-smooth {
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $gray-500;
}

// Page Setup

.page {
  position: relative;
  overflow: hidden;
  height: 100vh;

  .topbar {
    position: fixed;
    z-index: $topbar-zindex;
    top: 0;
    right: 0;
    left: 0;
    transition: all 0.5s ease;
    height: $topbar-height;

    display: flex;
    align-items: center;

    background-color: #f5f5f5 !important;
  }

  .topbar-menu {
    width: 100%;
  }

  .sidebar {
    position: absolute;
    // z-index: $topbar-zindex - 1;
    top: 0;
    transition: all 0.5s ease;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .main-cont {
    position: absolute;
    // z-index: $topbar-zindex - 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.5s ease;
    overflow-y: auto;
  }

  // Topbar Only (no sidebar)
  &.layout-topbar {
    .topbar {
      height: $topbar-height;
      border-bottom: 1px solid $gray-300;
    }

    .sidebar {
      width: 0;
      display: none;
    }

    .main-cont {
      top: $topbar-height;
    }
  }

  // Topbar and Sidebar (collapsible)
  &.layout-sidebar {
    .topbar {
      height: $topbar-height;
      border-bottom: 1px solid $gray-300;
      margin-left: $sidebar-left-width;

      &.collapsed {
        margin-left: $sidebar-left-width-collpase;
      }
    }

    .sidebar,
    .sidebar-left {
      left: 0;
      width: $sidebar-left-width;
      border-right: 1px solid $gray-300;

      .burger-style {
        position: fixed;
        border: none !important;
        width: 40px;
        height: 40px;
        z-index: 3000 !important;
        margin-left: $sidebar-left-width - 1.25rem;
        margin-top: 10px !important;
        transition: all 0.5s ease;
      }

      &.collapsed {
        width: $sidebar-left-width-collpase;

        .burger-style {
          margin-left: $sidebar-left-width-collpase - 20px;
        }
      }
    }

    .main-cont {
      top: $topbar-height;
      left: $sidebar-left-width;

      &.collapsed {
        left: $sidebar-left-width-collpase;
      }
    }
  }
}

// Branding

.navbar-branding {
  // height: $topbar-height;
  // border-bottom: 1px solid $gray-300;

  &.left {
    width: $sidebar-left-width;
  }
}

// Main Content Setup

.content-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  transition: all 0.5s ease;
  min-width: 1280px;

  .page-content {
    padding: 24px #{$grid-gutter-width};
  }
}

.page-header {
  padding: 16px #{$grid-gutter-width} 12px;
  // margin-bottom: 5px;
  // margin-top: 20px;

  display: flex !important;
  justify-content: space-between !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;

  &.border {
    border: 0 !important;
    border-bottom: 1px solid $gray-300 !important;
  }

  &.shadow {
    box-shadow: 0px 0px 15px 0px rgba(25, 104, 252, 0.2) !important;
  }
}

//
// Position Utilities

.position-top-0 {
  top: 0 !important;
}

.position-right-0 {
  right: 0 !important;
}

.position-bottom-0 {
  bottom: 0 !important;
}

.position-left-0 {
  left: 0 !important;
}

// Modal
.modal-backdrop {
  z-index: 2040;
}

.modal {
  z-index: 2050;
}
