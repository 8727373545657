// Themes :: PLATFORM
[data-theme="PLATFORM"] {
  // Variables
  $topbar-color: #fff;
  $sidebar-color: #2a3042;
  $menu-color: #9aa4c2;
  $menu-hover: #fff;
  $selected-menu-color: #ffff;

  // Custom CSS
  @import "../custom_theme";
}
