.breadcrumb {
  &__container {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 4px 0;
  }

  &__text {
    font-size: 16px;
  }
}

.map-header {
  .breadcrumb__container {
    margin-top: 15px;
  }
}

.breadcrumb__container li:before {
  content: "/";
  padding: 3px;
}

.breadcrumb__container li:first-child:before {
  content: "";
  padding: 0;
}
