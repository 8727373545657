.data-loader {
  margin-top: -90px;
}

.loader {
  height: 15px;
  width: 500px;
  border-radius: 10px;
  border: 4px solid transparent;
  position: relative;
  background-color: rgb(235, 229, 229);
}

.loader-name {
  font-size: 100px;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

.loader .loaderBar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: black;
  width: 0;
  animation: borealisBar 2s linear infinite;
}
