.table-class {
  max-height: 33vh;
}

.table-class .ant-table-tbody > tr > td {
  height: 8px;
  padding: 6px;
}

.modal-title-divider {
  border-top: 0.1px solid #e3dbdb;
  margin-top: 14px;
}
