// Themes :: INDUSTRY
[data-theme="INDUSTRY"] {
  // Variables
  $topbar-color: #fff;
  $sidebar-color: #1f510c;
  $menu-color: #fff;
  $menu-hover: #f7db6a;
  $selected-menu-color: #ffff;

  // Custom CSS
  @import "../custom_theme";
}
